<template>
  <div>
    <NModal
      preset="card"
      :title="modalTitle"
      :bordered="false"
      size="small"
      :segmented="{ content: 'hard', footer: 'hard' }"
      style="width: 550px;"
      :mask-closable="false"
      v-model:show="modalShow"
      @after-leave="clearModal"
    >
      <template #default>
        <div style="padding: 30px 0 20px;">
          <NForm
            ref="formRef"
            label-placement="left"
            :label-width="120"
            require-mark-placement="left"
            :model="formValue"
            :rules="formRules"
          >
            <NFormItem label="教材名称：" path="title">
              <NInput
                style="width: 335px;"
                maxlength="50"
                show-count
                clearable
                v-model:value="formValue.title"
              />
            </NFormItem>
            <NFormItem label="教材简介：">
              <NInput
                style="width: 335px;"
                type="textarea"
                maxlength="500"
                show-count
                clearable
                v-model:value="formValue.intro"
              />
            </NFormItem>
          </NForm>
        </div>
      </template>
      <template #footer>
        <NSpace justify="end">
          <NButton @click="closeModal">取消</NButton>
          <NButton type="primary" @click="confirmModal">确定</NButton>
        </NSpace>
      </template>
    </NModal>

    <NModal
      v-model:show="successModalShow"
      preset="dialog"
      :bordered="false"
      title="信息提示"
      :show-icon="false"
      :closable="false"
      :mask-closable="false"
      positive-text="跳转教材建设，继续完善"
      negative-text="关闭"
      @positive-click="confirmSuccessModal"
      @negative-click="cancelSuccessModal"
    >
      <div style="padding: 20px 0; font-size: 18px;">
        <NIcon size="40" :color="themeSettings.successColor" style="vertical-align: top; margin-top: -7px;">
          <CheckmarkCircleOutline />
        </NIcon>
        新增成功
      </div>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import { CheckmarkCircleOutline } from '@vicons/ionicons5';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { createMaterialTitle, editMaterialTitle, getMaterialDetail } from '@/api/material.js';

  import themeSettings from '@/settings/theme-settings.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import { materialTypeValMap } from '@/enums/material-type.js';

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const message = useMessage();
  const loading = ref(false);

  const emit = defineEmits(['list-update']);
  const props = defineProps({
    materialTypeVal: {
      required: true,
      type: Number
    }
  });

  const formRef = ref(null);
  const resReqDataMap = {
    title: {
      reqKey: 'Material[title]',
      default: '',
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    },
    intro: {
      reqKey: 'Material[introduce]',
      default: ''
    }
  };
  const getDefaultFormValue = () => {
    const tempFormValue = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempFormValue[key] = resReqDataMap[key].default;
    });
    return tempFormValue;
  };
  const formValue = reactive(getDefaultFormValue());
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqDataMap).forEach(key => {
      const rule = resReqDataMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();

  let materialID;
  const modalTitle = ref('新增教材');
  const modalShow = ref(false);
  const openModal = () => {
    modalTitle.value = '新增教材';
    modalShow.value = true;
  };
  function openEditModal(id) {
    materialID = id;
    modalTitle.value = '编辑教材';
    loading.value = true;
    getMaterialDetail({ 'Material[id]': materialID }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { title, introduce } = data;
        Object.assign(formValue, {
          title,
          intro: introduce
        });
        modalShow.value = true;
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  const clearModal = () => {
    Object.assign(formValue, getDefaultFormValue());
  };
  const closeModal = () => {
    modalShow.value = false;
  };
  const confirmModal = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        loading.value = true;
        const reqData = {};
        Object.keys(resReqDataMap).forEach(key => {
          reqData[resReqDataMap[key].reqKey] = formValue[key];
        });
        let reqFn;
        if (materialID) {
          reqData['Material[id]'] = materialID;
          reqData['Material[type]'] = 1;
          reqFn = editMaterialTitle;
        } else {
          reqData['Material[data_type]'] = props.materialTypeVal;
          reqFn = createMaterialTitle;
        }
        reqFn(reqData).then(res => {
          if (res.code === SUCCESS) {
            closeModal();
            createdId = res.data.id;

            if (materialID) {
              message.success('编辑信息成功');
              emit('list-update');
            } else {
              openSuccessModal();
            }
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      }
    });
  };

  let createdId;
  const successModalShow = ref(false);
  const openSuccessModal = () => {
    successModalShow.value = true;
  };
  const cancelSuccessModal = () => {
    emit('list-update');
  };
  const confirmSuccessModal = () => {
    switch (props.materialTypeVal) {
      case materialTypeValMap.NORMAL:
        router.push(`build?id=${createdId}`);
        break;
      case materialTypeValMap.TASK:
        router.push(`task-build?id=${createdId}`);
        break;
    }
  };

  defineExpose({
    openModal,
    openEditModal
  });
</script>