<template>
  <div>
    <NTabs type="card" class="tab-box" v-model:value="currentTabName">
      <NTab
        v-for="item in tabArr"
        :key="item.name"
        :name="item.name"
      >{{ item.label }}</NTab>
    </NTabs>
    <MaterialList
      v-for="item in tabArr"
      :key="item.name"
      v-show="item.name === currentTabName"
      :materialTypeVal="item.name"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { NTabs, NTab } from 'naive-ui';

  import MaterialList from './components/MaterialList.vue';

  import { materialTypeValNameMap } from '@/enums/material-type.js'

  const route = useRoute();

  const tabArr = Object.keys(materialTypeValNameMap).map(name => ({ label: materialTypeValNameMap[name], name: Number(name) }));
  const queryType = Number(route.query.type);
  const currentTabName = ref(queryType ? queryType : (queryType === 0 ? queryType : tabArr[0].name));
</script>

<style lang="less" scoped>
  .tab-box {
    background-color: #f7f7fa;

    :deep(.n-tabs-tab-pad) {
      width: 0;
    }

    :deep(.n-tabs-tab) {
      padding: 10px 20px;
      border: none !important;

      &.n-tabs-tab--active {
        background-color: #fff !important;
      }
    }

    :deep(.n-tabs-pad) {
      border-bottom: none !important;
    }
  }
</style>